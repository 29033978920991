<template>
  <ClientOnly>
    <MenuMobile
      v-if="!config.public.hideMenu && status !== 'pending'"
      :menu-data="data?.primaryNav"
      :footer-data="data.footerData"
    />
    <MenuDesktop
      v-if="!config.public.hideMenu && status !== 'pending' && !data?.primaryNav?.errors"
      :menu-data="data?.primaryNav"
      :social-links="data.footerData?.row_three_socmed_urls"
    />
  </ClientOnly>
  <Alert
    v-if="status !== 'pending' && !data?.additionalData?.errors"
    :data="data?.additionalData"
  />
  <Header :data="data?.additionalData" />
  <slot />
  <ClientOnly>
    <TheNewsletter
      v-if="status !== 'pending' && !data?.additionalData?.errors"
      :data="data?.additionalData"
    />
    <TheFooter
      v-if="status !== 'pending' && data?.footerData && !data.footerData.errors"
      :data="data.footerData"
    />
  </ClientOnly>
</template>

<script setup>
import { useLazyAsyncData } from '#app'
import MenuDesktop from '@/components/elements/MenuDesktop.vue'
import MenuMobile from '@/components/elements/MenuMobile.vue'
import Header from '@/components/elements/Header.vue'
import TheFooter from '@/components/elements/TheFooter.vue'
import TheNewsletter from '@/components/elements/TheNewsletter.vue'
import Alert from '@/components/elements/Alert.vue'
import { useGetData } from '@/composables/useJsonApi'
import { useRuntimeConfig } from '#app'

const config = useRuntimeConfig()

const { data, status } = useLazyAsyncData('data', () => useGetData())
</script>
